<template>
  <div class="my-5">
    <b-container class="shlajsna">
      <b-row class="mb-5">
        <b-col cols="12" lg="9" md="8" class="pr-4">
          <h1>{{ myTitle | removeLat }}</h1>
          <p>
            <b-avatar v-if="mUser.logo" :src="mUser.logo" size="1.75rem"/>
            <b-link :to="{name: 'UserMedia',  params: { lang: this.$root.lang.shortName, userId: mUser.hash } } ">
              {{ mUser.enterpriseName | removeLat }} 
            </b-link>
           | {{ dateTime(m.createdAt, "medium") }}
          </p>

          <div v-html="myDesc"></div>

          <div class="text-center">
            <b-img :src="m.posterUrl" fluid-grow />
          </div>

          <b-row class="metaData my-4" v-if="m.meta">
            <b-col cols="12" md="4">
              <div><span>{{ $t('videos.author')}}:</span> {{ m.author }}</div>
              <div><span>{{ $t('videos.source')}}:</span> {{ mUser.enterpriseName }}</div>
              <div><span>{{ $t('videos.year')}}:</span> {{ m.year }}</div>
              <div><span>{{ $t('videos.uploaded')}}:</span> {{ dateTime(m.createdAt, "medium") }}</div>
            </b-col>
            <b-col cols="12" md="4">
              <div><span>{{ $t('users.country')}}:</span> {{ mUser.country }}</div>
              <div><span>{{ $t('images.format')}}:</span> {{ myMetaData[0].image.format }}</div>
              <div><span>{{ $t('images.ppi')}}:</span> {{ myMetaData[0].image.resolution.x }}x{{ myMetaData[0].image.resolution.y }} ppi</div>
              <div><span>{{ $t('images.resolution')}}:</span> {{ myMetaData[0].image.geometry.width }}x{{ myMetaData[0].image.geometry.height }} px</div>
            </b-col>
            <b-col cols="12" md="4">
              <div><span>{{ $t('images.color')}}:</span> {{ myMetaData[0].image.colorspace }}</div>
              <div><span>{{ $t('images.fileSize')}}:</span> {{ myMetaData[0].image.filesize }}</div>
              <div><span>{{ $t('images.numberPixels')}}:</span> {{ myMetaData[0].image.numberPixels }}</div>
            </b-col>
          </b-row>
          <CategoriesSection :categories="m.categories" />
        </b-col>
        <b-col cols="12" lg="3" md="4" class="pl-4 aside align-self-center">
          <h2>{{ $t('images.download') }}</h2>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group v-model="selectedDownload" :options="downloadOptions" :aria-describedby="ariaDescribedby" 
              name="radio-btn-stacked" buttons stacked button-variant="light" block>
            </b-form-radio-group>
           </b-form-group>
           <b-button block variant="primary" @click="download" size="lg"> {{ $t('images.download-photo') }}</b-button>
        </b-col>
      </b-row>
      
      <b-overlay :show="showOverlay">
      <b-row  class="mt-4">
        <b-col cols="12">
          <h2>{{ $t('general.recently-added')}}</h2>
        </b-col>
        <b-col cols="12">
          <MediaGrid :media="media" />
        </b-col>
      </b-row>
      <b-row  v-if="loadMore" class="mb-4">
        <b-col  cols="12">
          <p class="text-center">
            <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
              {{ $t('general.load-more') }}
            </b-button>
          </p>
        </b-col>
      </b-row>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import { BImg, BAvatar, BFormRadioGroup } from 'bootstrap-vue'
import MediaGrid from '@/components/util/MediaGrid.vue'
import CategoriesSection from '@/components/util/CategoriesSection.vue'

export default {
  name: "ViewImage",
  components : { MediaGrid, BAvatar, BFormRadioGroup, BImg, CategoriesSection },
  data : function () {
    return {
      m : {},
      mUser: {},
      media: [],
      showOverlay: true,
      selectedDownload: 'fullres',
      downloadOptions: [],
      myMetaData:  {},
      loadMore: false,
      page: 0
    }
  },
  computed: {
    myTitle: function() {
      if (this.$root.lang.shortName === 'en') {
        return this.m.titleEn;
      } else {
        return this.m.title;
      }
    },
    myDesc: function() {
      if (this.$root.lang.shortName === 'en') {
        return this.m.descriptionEn;
      } else {
        return this.m.description;
      }
    },
    _lang: function() {
      return this.$root.lang
    }
  },
  created: async function() {
    this.setSingleImageData();

    this.showOverlay = false;

    // Other recent images
    if (window.sessionStorage.getItem("singleImagePagination")) {
      this.media = this.decodeJSON(window.sessionStorage.getItem("singleImagePagination"));
      this.loadMore = window.sessionStorage.getItem("singleImagePaginationHasNext");
      this.page = window.sessionStorage.getItem("singleImagePaginationPage");
      //console.log(this.media.length);
    } else {
     this.setRecentImageData();
    }
  },


  methods: {
    download: function() {
      var user = this.getAuthData();
      if (user === null) {
        // console.log("Trebalo bi da otvorim login modal");
       this.$router.push({name : "Login"});
      } else {
        window.location = "/cms/api/media/download/" + this.m.id + "/" + this.selectedDownload;
      }
    },
    loadMoreData: function() {
      this.page++;
      this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/image/?page=' + this.page).then(r => {
        this.media = this.media.concat(r.data.content);
        this.loadMore = r.data.hasNext;
        window.sessionStorage.setItem("singleImagePagination", this.encodeJSON(this.media));
        window.sessionStorage.setItem("singleImagePaginationHasNext", r.data.hasNext);
        window.sessionStorage.setItem("singleImagePaginationPage", this.page);
      }).catch(e => {
        var message = this.handleError(e);
      })
    },

    async setSingleImageData() {
      await this.$http.get('/api/media/get/' +  this.$route.params.imageId + '/' + this.$root.lang.IANA).then(r => {
          this.m = r.data;
          // console.log(JSON.parse(this.m.meta));
          this.myMetaData = JSON.parse(this.m.meta)
          this.mUser = r.data.createdBy;
          // console.log(this.m.categories);
          this.downloadOptions = [
            { text: this.$t('images.small-size') + ' | 500x400px', value: '500' },
            { text: this.$t('images.medium-size') + ' | 1000x750px', value: '1000' },
            { text: this.$t('images.large-size') + ' | 2000x1200px', value: '2000' },
            { text: this.$t('images.original-size') + " | " + this.myMetaData[0].image.geometry.width + "x" + this.myMetaData[0].image.geometry.height + "px", value: 'fullres' }
          ];
              // Count view:
          this.$http.post('/api/media/ping/', this.m).then(r => {  }).catch(e => { });
      }).catch(e => {
        this.handleError(e);
      });
    },

    async setRecentImageData() {
      this.showOverlay = true;
     await this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/image/?page=0').then(r => {
          this.media = r.data.content;
          this.showOverlay = false;
          this.loadMore = r.data.hasNext;
      }).catch(e => {
        this.handleError(e);
      });
      this.showOverlay = false;
    }
  },
  metaInfo: function() {
    return {
      title: this.m.title,
      description: this.m.description
    }
  },
  watch:{
    _lang : async function() {
      this.setSingleImageData();
      this.setRecentImageData();
    }
  }
}
</script>

<style>
</style>
